.screen {
  font-family: Cerebri Sans, Helvetica, Arial, sans-serif;
  background-color: rgba(66, 65, 73, 0.514);
}
.app {
  background: radial-gradient(
    circle at 0.8% 1%,
    rgba(167, 204, 192, 0.781) 40%,
    rgba(167, 233, 148, 0.589) 60.2%,
    rgba(204, 83, 139, 0.226) 100.2%
  );
}
.mainReport {
  width: 100%;
  background-image: url("../images/sky3.jpg");
  background-size: cover;
  background-position: center bottom;
}

h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 900;
  margin: 1px auto;
}

h1 {
  color: black;
}
#tempNow {
  font-size: 80px;
  text-align: right;
  font-weight: 900;
  padding-right: 0;
}
#more-details {
  box-shadow: 10px 15px 20px 5px rgba(0, 1, 7, 0.521);
  border-radius: 30px;
  background-color: rgba(223, 223, 223, 0.288);
  opacity: 0.9;
  margin: 0;
  padding: 20px;
}
.wtdetail {
  font-size: 18px;
  color: rgb(117, 30, 15);
}

.forecast {
  text-align: center;
  background: radial-gradient(
    circle at 0.8% 1%,
    rgba(167, 204, 192, 0.781) 40%,
    rgba(167, 233, 148, 0.589) 60.2%,
    rgba(204, 83, 139, 0.226) 100.2%
  );
}
.weeklyforecast,
.hourlyforecast {
  height: 400px;
}
